import { getExpressSectionSourceDataByKey } from "@src/pages/ExpressForm/express-data";
import { AccountDetails, NotifierDetails, ServiceProvider, NotifierRoles } from "@src/types";
import { FILE_NAME_REGEX, ACCEPTABLE_MIME_TYPES, COMPRESSABLE_MIME_TYPES, MAX_FILE_SIZE, MIME, PDF_MIME, READABLE_MAX_FILE_SIZE_NON_PDF, READABLE_MAX_FILE_SIZE_PDF } from "../utils/Constants";
import Compressor from 'compressorjs';

// either service provider needs a will or role in executor or solicitor will is required
export const isWillRequired = (
    accounts: ReadonlyArray<AccountDetails> | undefined,
    notifier: NotifierDetails | undefined,
    spMap: Record<string, ServiceProvider>,
    intestacyFlow: boolean,
    collectWill: boolean
): boolean => {
    if (intestacyFlow) return collectWill;
    if (notifier?.role === NotifierRoles.Administrator) return false;

    const isExecutor = ["sole_executor", "executor"].includes(notifier?.role ?? "empty_string");
    const isReq = accounts?.some(
        (account: AccountDetails) =>
            account.companyId && spMap[account.companyId]?.willNeeded
    );
    // if no accounts are added then will is required
    const askForWillAndProbate = isReq || isExecutor || accounts?.length === 0;

    return askForWillAndProbate;
};

export const isWillRequiredExpressForm = (
    serviceProvider: ServiceProvider,
    collectWill: boolean
) => {
    if (serviceProvider.intestacyFlow) return collectWill;

    // sp does not required will
    if (!serviceProvider.willNeeded) return false;

    const notifierInfo: NotifierDetails | undefined = getExpressSectionSourceDataByKey("notifier");
    // notifier details are not provided so will is required
    if (!notifierInfo) return true;

    return true;
};

export function sanitizeFileName(filename: string): string {
    let sanitized = filename.trim();
    sanitized = sanitized.replace(FILE_NAME_REGEX, "");

    sanitized = sanitized.slice(0, 24);

    return sanitized
        .replace(/\.+/g, ".")
        .replace(/\/+/g, "")
        .replace(/\\+/g, "");
}

export function isAllowedMimeType(mimeType: string): boolean {
    return ACCEPTABLE_MIME_TYPES.includes(mimeType.toLowerCase());
}

export function isValidSize(size: number): boolean {
    if (size > MAX_FILE_SIZE) return false
    return true;
}

export function isCompressableType(file: File): boolean {
    return COMPRESSABLE_MIME_TYPES.includes(file.type)
}


export function fileSizeErrorByMime(file_type: MIME | string): string {
    let size: string = READABLE_MAX_FILE_SIZE_NON_PDF;
    if (file_type === PDF_MIME) {
        size = READABLE_MAX_FILE_SIZE_PDF;
    }

    return `File uploaded is larger than ${size}, please upload files that are within ${size} / as large as ${size}`;
}

export function compress(file: File): Promise<File> {
    console.log(file.type, (file.size / 1024));

    if (!isCompressableType(file)) {
        console.log("Compression did not occur (type not supported)");
        return Promise.resolve(file);
    }

    return new Promise((resolve, _reject) => {
        new Compressor(file, {
            mimeType: "image/jpeg",
            success: (compressed: File) => {
                console.log("Compressed file:", compressed.type, (compressed.size / 1024));
                resolve(compressed);
            },
            error: (err: any) => {
                console.log("Compression failed", err);
                resolve(file);
            }
        });
    });
}