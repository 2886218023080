import {
  AccountNextStep,
  ServiceProviderType,
  Account,
  AccountStatuses,
  AccountType,
  MeterType,
  AccountManagement,
} from "@src/types";
import { assertUnreachable } from "./Functions";

const oneMonth = 1000 * 60 * 60 * 24 * 30;
export const readableAccountStatuses = (s?: AccountStatuses, fallback?: string): string => {
  switch (s) {
    case AccountStatuses.Blocked:
      return "";

    case AccountStatuses.Processing:
      return "processing";

    case AccountStatuses.Settled:
      return "Account Settld";

    default:
      return fallback || "unknown";
  }
  // assertUnreachable(s);
};

export const readableAccountStatus = (account: Account): string => {
  switch (account.status) {
    case AccountStatuses.Blocked:
      return "Processing account.";

    case AccountStatuses.Processing:
      if (account.estimateSettledBy) {
        return `Expecting completion by ${formatDate(account.estimateSettledBy)}`;
      }

      if (account.initialLetterSentAt) {
        return `Expecting completion by ${formatDate(
          new Date(Date.parse(account.initialLetterSentAt) + oneMonth).toISOString()
        )}`;
      }

      return "Processing account.";

    default:
      return "";
  }
};

const formatDate = (dateStr: string) => {
  const [year, month, day] = dateStr.substring(0, 10).split(/-/);

  return `${day} ${monthName(month)} ${year}`;
};

const monthName = (month: string) => {
  return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][
    parseInt(month, 10) - 1
  ];
};



export const readableAccountNextSteps = (
  a?: AccountNextStep | null,
  type?: ServiceProviderType
): string => {
  switch (a) {
    case AccountNextStep.Cancel:
      return type === ServiceProviderType.Insurance ? "Cancel policy" : "Close account";
    case AccountNextStep.MoveToAnotherService:
      return type === ServiceProviderType.Mobile
        ? "Move to a new mobile network"
        : "Move to another service";
    case AccountNextStep.MoveToPayAsYouGo:
      return "Move to Pay as you go";
    case AccountNextStep.RequestAccountBalance:
      return `Request ${type === ServiceProviderType.Insurance ? "policy" : "account"
        } balance at the date of death`;
    case AccountNextStep.UpdateAccountHolder:
      return type === ServiceProviderType.Insurance
        ? "Update policy holder"
        : "Update primary account holder";
    case AccountNextStep.RequestInformation:
      return "Request Information";
    case undefined:
    case null:
      return "Unknown";
  }
  assertUnreachable(a as never);
};

export const readableAccountType = (type?: AccountType, other?: string) => {
  switch (type) {
    case AccountType.CurrentAccount:
      return `Current account`;
    case AccountType.SavingsAccount:
      return "Savings account";
    case AccountType.CashISA:
      return "Cash ISA";

    case AccountType.RetirementAccount:
      return "Retirement account";
    case AccountType.Annuities:
      return "Annuities";
    case AccountType.WorkplacePension:
      return "Workplace pension";

    case AccountType.Remortgage:
      return "Remortgage";
    case AccountType.FirstTimeBuyer:
      return "First-time buyer";
    case AccountType.BuyToLet:
      return "Buy-to-let";

    case AccountType.Vehicle:
      return "Vehicle";
    case AccountType.BreakdownCover:
      return "Breakdown cover";
    case AccountType.Home:
      return "Home";
    case AccountType.Health:
      return "Health";
    case AccountType.Travel:
      return "Travel";
    case AccountType.Business:
      return "Business";
    case AccountType.Life:
      return "Life";
    case AccountType.Disability:
      return "Disability";
    case AccountType.Pet:
      return "Pet";

    case AccountType.PayAsYouGo:
      return "Pay as you go";

    case AccountType.Contract:
      return "Contract";

    case AccountType.Personal:
      return "Personal";

    case AccountType.PersonalAndBusiness:
      return "Personal and business accounts";

    case AccountType.PersonalAccidentInsurance:
      return "Personal accident insurance";
    case AccountType.PrivateMedicalInsurance:
      return "Private medical insurance";
    case AccountType.HospitalCashPlan:
      return "Hospital cash plan";
    case AccountType.CancerCover:
      return "Cancer cover";

    case AccountType.Other:
      return other || "Other";
    case AccountType.Unknown:
    case undefined:
    case null:
      return "Unknown";
  }
  assertUnreachable(type);
};

export const readableMeterType = (meterType?: MeterType) => {
  switch (meterType) {
    case MeterType.SmartMeter:
      return "Smart meter";

    case MeterType.Credit:
      return "Credit";

    case MeterType.PayAsYouGo:
      return "Pay as you go";

    case MeterType.Unknown:
      return "Not known";

    case undefined:
    case null:
      return "Unknown";
  }
  assertUnreachable(meterType);
};


export function isSelfManaged(account: Account): boolean {
  return (
    !account.serviceProvider?.accountManagement ||
    account.serviceProvider?.accountManagement === AccountManagement.NotManaged ||
    account.serviceProvider?.accountManagement === AccountManagement.NotifyOnly ||
    account.serviceProvider?.accountManagement === AccountManagement.Unknown
  );
}

export function isAdditonalServices(account: Account): boolean {
  return !!account.additionalServicesAccount;
}

export function isRequiresAttention(account: Account): boolean {
  return (
    account.status === AccountStatuses.Blocked ||
    account.status === AccountStatuses.Cancelled
  );
}

export function isInProgress(account: Account): boolean {
  return (account.status === AccountStatuses.Processing);
}

export function isCompleted(account: Account): boolean {
  return (account.status === AccountStatuses.Settled);
}
export function hasUnreadMessage(account: Account): boolean {
  return (!!account.unreadMsg);
}