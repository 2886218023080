export enum Colors {
  TEAL = "#41EAD4",
  RED = "#F50744",
  LIGHT_PURPLE = "#9D8DF1",
  DARK_BLUE = "#020887",
}

export const SignupReferralOptions = [
  { value: "word_of_mouth", label: "Word of mouth" },
  { value: "website", label: "Another Website" },
  { value: "online_search", label: "Online search" },
  { value: "news", label: "In the news" },
  { value: "social_media", label: "Social media" },
  { value: "care_home", label: "Care home" },
  { value: "celebrant_or_officiant", label: "Celebrant/Officiant" },
  { value: "charity", label: "Charity" },
  { value: "funeral_director", label: "Funeral director" },
  { value: "health_care_worker", label: "Health care worker" },
  { value: "hospice", label: "Hospice" },
  { value: "local_council", label: "Local council" },
  { value: "registrar", label: "Registrar" },
  { value: "other", label: "Other" },
];

export enum SettldContactInfo {
  Telephone = "0333 111 1111",
  ContactUs = "https://www.settld.care/contact/",
  Email = "support@settld.care"
}

export const FILE_NAME_REGEX = /[^\w.\- ]+/g;
export const ACCEPTABLE_FILE_TYPES: string = ".pdf,.png,.jpg,.jpeg";
export const MAX_FILE_SIZE = 3 * 1024 * 1024;

export const READABLE_ACCEPTABLE_MIME_TYPES: string = "PDF, JPEG, PNG";
export const READABLE_MAX_FILE_SIZE: string = "3 MB";
export const READABLE_MAX_FILE_SIZE_PDF: string = "3 MB";
export const READABLE_MAX_FILE_SIZE_NON_PDF: string = "6.5 MB";
export type MIME = "application/pdf" | "image/png" | "image/jpeg" | "image/jpg";
export const PDF_MIME = "application/pdf";

export const FIlE_RESTRICTIONS_MSG: string =
  `Supported types ${READABLE_ACCEPTABLE_MIME_TYPES}, 
   Pdf Max size: ${READABLE_MAX_FILE_SIZE_PDF},
   JPEG/PNG Max size: ${READABLE_MAX_FILE_SIZE_NON_PDF}.`;

export const ACCEPTABLE_MIME_TYPES: string[] =
  [
    "application/pdf",
    "image/png",
    "image/jpeg",
    "image/jpg"
  ];

export const AcceptedFileTypes =
{
  "application/pdf": [".pdf"],
  "image/png": [".png"],
  "image/jpeg": [".jpg", ".jpeg"],
};

export const COMPRESSABLE_MIME_TYPES: string[] =
  [
    "image/png",
    "image/jpeg",
    "image/jpg"
  ];
