import * as React from "react";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Container, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { colors } from "@styles/constants";
import { Button } from "@atoms/Button";
import { Header } from "@atoms/Header";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { SideImageTemplate } from "@templates/SideImageTemplate";
import { getExpressData, putExpressAdditionalServicesData } from "./express-data";
import { AdditionalHelpDetails, additionalServicesToUi, YesNo } from "@src/types";
import { YesNoCheckboxField } from "@atoms/YesNoField";
import { updateAdditionalServices } from "@api/caseApi";
import { useScrollToTop } from "@src/hooks/useScrollToTop";

export type ExpressAdditionalServicesPageProps = {
  serviceProvider: { companyName: string };
  next: () => void;
};

export const ExpressAdditionalServicesPage: React.FC<ExpressAdditionalServicesPageProps> = ({
  serviceProvider,
  next
}) => {
  return <ExpressAdditionalServicesPageView serviceProvider={serviceProvider} next={next} />;
};

export type ExpressAdditionalServicesPageViewProps = {
  serviceProvider: { companyName: string };
  next: () => void;
};

export const ExpressAdditionalServicesPageView: React.FC<
  ExpressAdditionalServicesPageViewProps
> = ({ serviceProvider, next }) => {
  useScrollToTop();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [services, setServices] = React.useState<AdditionalHelpDetails>({});

  const onFieldChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string | undefined } }) => {
      setServices((s) => ({ ...s, [name]: value }));
    },
    [setServices]
  );

  const onFieldValueChange = (name: string) => (value: YesNo) =>
    onFieldChange({ target: { name, value } });
  const Entry = ({ label, name }: { label: string; name: keyof AdditionalHelpDetails }) => (
    <FormField> <YesNoCheckboxField
      label={label}
      value={services[name]}
      onValueChange={onFieldValueChange(name)}
    /> </FormField>
  );

  const handleNext = React.useCallback(() => {
    putExpressAdditionalServicesData(services);

    const query = new URLSearchParams(new URL(getExpressData().formUrl).search);
    const caseId = query.get("case_id");
    const signature = query.get("sig");
    updateAdditionalServices({ caseId: caseId, signature: signature, record: services, source: "express_form" })
      .then((_s) => {
        // console.log(s);
        next();
      })
      .catch((e) => {
        console.log(e);
        next();
      });
  }, [services, next]);

  return (
    <SideImageTemplate
      headerType="help"
      image={<img src="/images/stock/express-form-submitted.svg" width="100%" alt="Family" />}
    >
      <Stack rowGap={4}>
        {matches ? (
          <>
            <Header level={1} color={colors.accentTeal}>
              {serviceProvider.companyName} successfully notified
            </Header>

            <Header level={2}>
              Do you need help with other services?
            </Header>
          </>
        ) : (
          <>
            <Stack columnGap={1} direction="row">
              <CheckCircleOutlineOutlinedIcon
                fontSize="small"
                sx={{ color: colors.lightTeal }} />
              <Typography variant="body1" color={colors.accentTeal}>
                <b>{serviceProvider.companyName} successfully notified</b>
              </Typography>
            </Stack>
            <Header
              level={1}
              color={colors.bodyText}> Do you need help with other services?
            </Header>
          </>
        )}

        <Typography variant="body1"> Any services you select from the list below, will be provided via your accounts
          dashboard. This gives you one place to turn to for complete peace of mind. </Typography>

        <Container>
          <FormStack spacing={0}>
            {additionalServicesToUi.map(([ui, internal_field]: any) => (
              <Entry key={internal_field} label={ui} name={internal_field} />
            ))}
          </FormStack>
        </Container>

        <FormStack>
          <FormField>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="primary" size="large" onClick={handleNext}> Skip </Button> <Button
                variant="primary"
                size="large"
                onClick={handleNext}> Next </Button>
            </div>
          </FormField>
        </FormStack>
      </Stack>
    </SideImageTemplate>
  );
};
