import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { signupRequest } from "@api/sessionApi";
import { login } from "@utils/session";
import { BusyOverlay } from "@atoms/BusyOverlay";
import { Button } from "@atoms/Button";
import { Header } from "@atoms/Header";
import { PasswordInput } from "@atoms/PasswordInput";
import { TextInput } from "@atoms/TextInput";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { SideImageTemplate } from "@templates/SideImageTemplate";
import { Link } from "@atoms/Link";
import { ValidationError } from "@src/utils/Errors";

type State = {
  readonly busy: boolean;
  readonly errorMessage: string;
  readonly errors: RecordErrors;
  readonly record: Record;
  readonly validated: boolean;
};

type Record = {
  readonly password: string;
  readonly passwordConfirmation: string;
};

type RecordErrors = {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  password?: string;
  passwordConfirmation?: string;
  referral?: string;
};

export type PasswordSetupPageProps = {
  readonly nextPageURL: string;
};

export const PasswordSetupPage: React.FC<PasswordSetupPageProps> = ({ nextPageURL }) => {
  const { website } = useParams() as { website: string };

  const [state, setState] = React.useState<State>({
    busy: false,
    errorMessage: "",
    errors: {},
    record: {
      password: "",
      passwordConfirmation: ""
    },
    validated: false
  });

  const { busy, errorMessage, errors, record, validated } = state;
  const navigate = useNavigate();

  const onChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string } }) => {
      setState((s) => ({
        ...s,
        errors: {
          ...s.errors,
          [name]: undefined
        },
        record: { ...s.record, [name]: value },
        validated: false
      }));
    },
    []
  );

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();
      const errors = recordErrors(record);

      if (Object.keys(errors).length !== 0) {
        setState((s) => ({ ...s, validated: true, errors, errorMessage: "" }));
        return;
      }

      setState((s) => ({ ...s, busy: true, errors, errorMessage: "" }));

      const data = { ...record, captcha: "none" };

      signup(data).then(
        ({ session }) => {
          setState((s) => ({ ...s, busy: false }));
          login(session.token);
          navigate(nextPageURL, { replace: true });
        },
        (error) => {
          if (error instanceof ValidationError) {
            setState((s) => ({
              ...s,
              busy: false,
              errors: errorsFromServer(error),
              validated: true
            }));
          } else {
            setState((s) => ({ ...s, busy: false, errorMessage: error.message }));
          }
        }
      );
    },
    [record, navigate, nextPageURL]
  );

  const removeErrorMessage = React.useCallback(() => {
    setState((s) => ({ ...s, errorMessage: "" }));
  }, []);

  return (
    <PasswordSetupPageView
      emailAddress="marco@settld.care"
      validated={validated}
      record={record}
      errors={errors}
      website={website}
      errorMessage={errorMessage}
      busy={busy}
      onSubmit={onSubmit}
      onChange={onChange}
      removeErrorMessage={removeErrorMessage}
    />
  );
};

export type PasswordSetupPageViewProps = {
  readonly emailAddress: string;
  readonly validated: boolean;
  readonly record: Record;
  readonly errors: RecordErrors;
  readonly website: string;
  readonly errorMessage: string;
  readonly busy: boolean;
  onSubmit: (ev: any) => void;
  onChange: (ev: any) => void;
  removeErrorMessage: () => void;
};

export const PasswordSetupPageView: React.FC<PasswordSetupPageViewProps> = ({
  validated,
  emailAddress,
  record,
  errors,
  errorMessage,
  busy,
  onSubmit,
  onChange,
  removeErrorMessage
}) => {
  return (
    <BusyOverlay hidden={!busy}>
      <SideImageTemplate
        headerType="help"
        errorMessage={errorMessage}
        removeErrorMessage={removeErrorMessage}
        image={
          <img
            src="/images/stock/signup.svg"
            width="100%"
            alt="Family"
            style={{ paddingTop: "100px" }}
          />
        }
      >
        <form onSubmit={onSubmit} noValidate autoComplete="off">
          <Stack rowGap={4}>
            <Box>
              <Header>Please set up a password</Header>
              <Typography variant="body1">
                To be able to make effective use of Settld service, you need to set up an account
                with us. This will allow you to return to our service to follow the progress of the
                accounts by using your email address and password.
              </Typography>
            </Box>

            <FormStack>
              <FormField twoThirdsWidthByItself label="Email address">
                <TextInput name="email" disabled value={emailAddress} />
              </FormField>

              <FormField twoThirdsWidthByItself label="Password">
                <PasswordInput
                  name="password"
                  value={record.password || ""}
                  error={!validated ? null : errors.password}
                  onChange={onChange}
                />
              </FormField>

              <FormField twoThirdsWidthByItself label="Confirm password">
                <PasswordInput
                  name="passwordConfirmation"
                  value={record.passwordConfirmation || ""}
                  error={!validated ? null : errors.passwordConfirmation}
                  onChange={onChange}
                />
              </FormField>
            </FormStack>

            <FormStack>
              <FormField>
                <div>
                  By clicking the ‘Continue’ button, you are creating a Settld account and you agree
                  to Settld's{" "}
                  <Link
                    inline
                    target="_blank"
                    to="https://www.settld.care/settld-terms-and-conditions"
                  >
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link
                    inline
                    target="_blank"
                    to="https://www.settld.care/settld-privacy-policy-2"
                  >
                    Privacy Policy
                  </Link>
                  .
                </div>
              </FormField>
            </FormStack>

            <FormStack>
              <FormField slightlyMoreThanHalfWidth>
                <Button submit variant="primary" size="large" disabled={busy} fullWidth={true}>
                  Continue
                </Button>
              </FormField>
            </FormStack>
          </Stack>
        </form>
      </SideImageTemplate>
    </BusyOverlay>
  );
};

const recordErrors = (record: Record) => {
  const errors = {
    password: !record.password
      ? "required"
      : [/[0-9]/, /[a-zA-Z]/, /[^0-9a-zA-Z]/, /......../].every((re) =>
          re.test(record.password)
        )
      ? undefined
      : "Please ensure your password is at least 8 characters in length, and contains a combination of letters, numbers, and symbols",
    passwordConfirmation: !record.passwordConfirmation ? "required" : undefined
  };

  return JSON.parse(JSON.stringify(errors));
};

const errorsFromServer = (ve: ValidationError) => {
  const errors = {
    password: ve.errors.password ? ve.errors.password[0] : undefined,
    passwordConfirmation: ve.errors.passwordConfirmation
      ? ve.errors.passwordConfirmation[0]
      : undefined
  };

  return JSON.parse(JSON.stringify(errors));
};

const signup = async (args: ServerRecord): Promise<{ session: { token: string } }> => {
  const result = await signupRequest(args);
  if (result.error) {
    throw new Error(result.error === "user_exists" ? "You have already registered; please log in." : result.error);
  }
  return result.data;
};

type ServerRecord = {
  password: string;
  passwordConfirmation: string;
  captcha: string;
};