import { Account } from "@src/types";
import { deprecatedAdditionalServices, DeprecatedAdditionalHelpDetails, AdditionalHelpDetails, allAdditionalServices } from "@src/types/additional-services";
import { Case } from "@src/types/Case";


export function isDepreacatedService(field: keyof AdditionalHelpDetails): boolean {
    return deprecatedAdditionalServices.includes(field as keyof DeprecatedAdditionalHelpDetails);
}

export function servicesFromCase(caseRecord: Case): AdditionalHelpDetails {
    const result = {} as AdditionalHelpDetails;
    allAdditionalServices.forEach(({ key }) => {
        result[key] = caseRecord[key];
    });
    return result;
};

export function servicesFromAccount(accounts: ReadonlyArray<Account>): ReadonlyArray<keyof AdditionalHelpDetails> {
    const result: Array<keyof AdditionalHelpDetails> = [];
    accounts.forEach((account) => {
        if (account.additionalServicesAccount && account.additionalServicesDetails) {
            result.push(account?.additionalServicesDetails?.type);
        }
    });
    return result;
};