import { Identifiable } from "./Identifiable";
import { NotifierDetails } from "./FormData";
import { NotifierRoles, ServiceProvider } from "@customTypes/index";
import { getExpressSectionSourceDataByKey } from "@src/pages/ExpressForm/express-data";
import { AccountDetails } from "./Account";
export interface Document extends Identifiable {
  readonly id: string;
  readonly downloadUrl: string;
  readonly filename: string;
  readonly uploadedAt?: Date;
  readonly updatedAt?: Date;
  readonly tags?: string[];
}

export const ACCEPTABLE_FILE_TYPES: string = ".pdf,.png,.jpg,.jpeg";
export const MAX_FILE_SIZE = 3 * 1024 * 1024;

export const ACCEPTABLE_MIME_TYPES: string[] = [
  "application/pdf",
  "image/png",
  "image/jpeg",
  "image/jpg"
];

export const AcceptedFileTypes = {
  "application/pdf": [".pdf"],
  "image/png": [".png"],
  "image/jpeg": [".jpg", ".jpeg"],
};

export type DocumentShell = {
  id: string;
  name: string;
  tags?: ReadonlyArray<string>;
};

export interface DocumentsDetails {
  readonly proofOfAddressIds: string[];
  readonly proofOfDeathIds: string[];
  readonly willAndProbateIds: string[];
  readonly grantOfRepresentationIds: string[];
  readonly appliedForGrantOfRepresentationOn?: string;
  readonly tags?: string[];
}

export type UploadRecord = {
  id: string;
  filename: string;
};

export enum FileTags {
  ProofOfAddress = "proof_of_address",
  ProofOfDeath = "proof_of_death",
  Will = "will",
  GrantOfRepresentation = "grant_of_representation",
  NokId = "nok_id",
  ExecutorId = "executor_id",
  ExecutorGoa = "executor_goa",
  NokGoa = "nok_goa",
}

export const fileTagsToUiInfo = {
  [FileTags.ProofOfAddress]: "Proof of Address",
  [FileTags.ProofOfDeath]: "Proof Of Death",
  [FileTags.Will]: "Will and Probate",
  [FileTags.GrantOfRepresentation]: "Grant of Representation",
  [FileTags.NokId]: "ID Document for next of kin",
  [FileTags.ExecutorId]: "ID Document for Executor of the Will",
  [FileTags.NokGoa]: "Grant of Authority for next of kin",
  [FileTags.ExecutorGoa]: "Grant of Authority for Executor of the Will",
};
// either service provider needs a will or role in executor or solicitor will is required

export const isWillRequired = (
  accounts: ReadonlyArray<AccountDetails> | undefined,
  notifier: NotifierDetails | undefined,
  spMap: Record<string, ServiceProvider>,
  intestacyFlow: boolean,
  collectWill: boolean
): boolean => {
  if (intestacyFlow) return collectWill;
  if (notifier?.role === NotifierRoles.Administrator) return false;

  const isExecutor = ["sole_executor", "executor"].includes(notifier?.role ?? "empty_string");
  const isReq = accounts?.some(
    (account: AccountDetails) =>
      account.companyId && spMap[account.companyId]?.willNeeded
  );
  // if no accounts are added then will is required
  const askForWillAndProbate = isReq || isExecutor || accounts?.length === 0;

  return askForWillAndProbate;
};


export const isWillRequiredExpressForm = (
  serviceProvider: ServiceProvider,
  collectWill: boolean
) => {
  if (serviceProvider.intestacyFlow) return collectWill;

  // sp does not required will
  if (!serviceProvider.willNeeded) return false;

  const notifierInfo: NotifierDetails | undefined = getExpressSectionSourceDataByKey("notifier");
  // notifier details are not provided so will is required
  if (!notifierInfo) return true;

  return true;
};