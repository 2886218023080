import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { RadioGroupInput } from "@src/components/atoms/RadioGroupInput";
import { ACCEPTABLE_FILE_TYPES, ACCEPTABLE_MIME_TYPES, FileTags, fileTagsToUiInfo, MAX_FILE_SIZE, UploadRecord } from "@src/types/Document";
import { DashboardInfoContext } from "@src/store/DashboardProvider";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import { red } from "@mui/material/colors";
import { attach, createDocumentAndUpload } from "@src/services/api/caseApi";
import { compress, fileSizeErrorByMime } from "@src/helpers/documents-helpers";

export type DialogState = {
    documents: File[];
    errors: undefined | string;
    busy: boolean;
    fileTag: FileTags | undefined
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export async function uploadDocuments(documents: File[], fileTag: FileTags, caseId: any, signature: any) {
    const uploadAndAttachToCasePromises = documents.map(async (file: File) => {
        const filename = `${fileTag}_${file.name}`;
        try {
            const docToS3AndBackendRes: UploadRecord = await createDocumentAndUpload(caseId, signature, file, filename, [fileTag]);
            try {
                const linkUploadedDocToCaseRes = await attach({ caseId, signature, documentId: docToS3AndBackendRes.id })
                return { success: true, document: linkUploadedDocToCaseRes.id, filename };
            } catch (linkUploadedDocToCaseErr) {
                return { success: false, document: null, error: linkUploadedDocToCaseErr, filename };
            }
        } catch (docToS3AndBackendError) {
            return { success: false, document: null, error: docToS3AndBackendError, filename };
        }
    })
    return Promise.all(uploadAndAttachToCasePromises);
}


const initState: DialogState = { documents: [], errors: undefined, busy: false, fileTag: undefined };

export const FileUploadDialog: React.FC<{ dialogVisible: boolean, hideDialog: any }> = ({ dialogVisible, hideDialog }) => {
    const [dialogState, setDialogState] = React.useState<DialogState>(initState);
    const { state: dashBoardState, dispatch } = React.useContext(DashboardInfoContext);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;

        const files = Array.from(event.target.files);
        const invalidFileNames: string[] = [];

        // Only check for acceptable mime types before compression.
        const filesToCompress = files.filter((file) => {
            if (!ACCEPTABLE_MIME_TYPES.includes(file.type)) {
                invalidFileNames.push(`${file.name} (invalid type: ${ACCEPTABLE_FILE_TYPES})`);
                return false;
            }
            return true;
        });

        // Compress all valid files.
        const compressedFiles: File[] = await Promise.all(
            filesToCompress.map((file) => compress(file))
        );

        // Now check that each compressed file is within the maximum size.
        const finalValidFiles: File[] = [];
        compressedFiles.forEach((file) => {
            if (file.size > MAX_FILE_SIZE) {
                invalidFileNames.push(`${file.name}: ` + fileSizeErrorByMime(file.type));
            } else {
                finalValidFiles.push(file);
            }
        });

        // Update state based on errors or add valid documents.
        if (invalidFileNames.length > 0) {
            setDialogState((s) => ({
                ...s,
                busy: false,
                errors: `Invalid file(s): ${invalidFileNames.join(", ")}.`,
            }));
        } else {
            setDialogState((s) => ({
                ...s,
                busy: false,
                errors: undefined,
                documents: [...s.documents, ...finalValidFiles],
            }));
        }
    };

    const handleRemoveFile = (fileName: string) => {
        setDialogState((s) => ({
            ...s, documents: s.documents.filter((file) => file.name !== fileName)
        }));
    };

    const handleTagChange = (value: string) => setDialogState((s) => ({ ...s, errors: undefined, busy: false, fileTag: value as FileTags }));

    const exitDialog = React.useCallback(() => {
        setDialogState(initState);
        dispatch({ type: "REFRESH" })
        hideDialog();
    }, [hideDialog]);

    const handleSubmit = async () => {

        if (dialogState.fileTag === undefined) {
            setDialogState((s) => ({ ...s, errors: "Select Document Type", busy: false, }));
            return;
        }
        if (dialogState.documents.length === 0) {
            setDialogState((s) => ({ ...s, errors: "Upload at least One Document", busy: false, }));
            return;
        }

        setDialogState((s) => ({ ...s, busy: true, errors: undefined }));
        try {
            const results = await uploadDocuments(dialogState.documents, dialogState.fileTag, dashBoardState.caseId, dashBoardState.signature);
            const errors = results.filter(r => !r.success).map(r => `[${r.filename}][${r.error}]`);
            if (errors.length > 0) {
                setDialogState((s) => ({ ...s, errors: `Some uploads failed. Please try again.[Error]: [${errors.join(" ] , [ ")}] `, busy: false }));
            } else {
                exitDialog();
            }
        } catch {
            setDialogState((s) => ({ ...s, errors: "Upload failed. Please try again.", busy: false }));
        }
    };

    const radioFields = Object.keys(fileTagsToUiInfo).map((key) => ({ label: fileTagsToUiInfo[key as FileTags], value: key }));

    return (
        <Dialog open={dialogVisible} onClose={hideDialog} fullScreen={fullScreen}>
            <DialogTitle>
                Upload Documents
                {dialogState.busy && !dialogState.errors && <Box sx={{ width: '100%' }}><LinearProgress /></Box>}
            </DialogTitle>
            <DialogContent dividers>

                <form>
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                        style={{ width: "100%" }}
                    >
                        Upload file
                        <VisuallyHiddenInput type="file" multiple onChange={handleFileChange} />
                    </Button>
                    <Typography variant="body2" sx={{ marginBottom: 2, textAlign: "center" }}>
                        {dialogState.documents.length} file{dialogState.documents.length !== 1 ? 's' : ''} selected.
                    </Typography>
                    {dialogState.errors && (
                        <Typography color="error" sx={{ marginBottom: 2, textAlign: "center" }}>
                            < ErrorIcon sx={{ color: red[500] }} />
                            {dialogState.errors}
                        </Typography>
                    )}

                    <Box>
                        {dialogState.documents.map((file) => (
                            <Box
                                key={file.name}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: 1,
                                    borderBottom: '1px solid #ccc',
                                }}
                            >
                                <Typography variant="body2">{file.name}</Typography>
                                <IconButton onClick={() => handleRemoveFile(file.name)} size="small">
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                    <RadioGroupInput
                        value={dialogState.fileTag ?? ''}
                        fields={radioFields}
                        onValueChange={handleTagChange}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={exitDialog} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog >
    );
};

