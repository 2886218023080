import { YesNo } from "./FormData";
// fetch the list from backend for dynamic forms

export const allAdditionalServices = [
    {
        label: "Receive your inheritance faster",
        key: "requestInheritanceFundingFinance",
        status: "active",
    },
    {
        label: "Check for missing assets",
        key: "requestMissingAssetSearch",
        status: "active",
    },
    {
        label: "Probate and Estate Admin",
        key: "requestProbateSolicitor",
        status: "active",
    },
    {
        label: "House clearance",
        key: "requestHomeRemoval",
        status: "active",
    },
    {
        label: "Property valuation & sale",
        key: "requestPropertyValuationService",
        status: "active",
    },

    // Deprecated
    {
        label: "Funeral Funding Finance",
        key: "requestFuneralFundingFinance",
        status: "deprecated",
    },
    {
        label: "Probate bridging loans",
        key: "requestProbateBridgingLoans",
        status: "deprecated",
    },
    {
        label: "Arranging the funeral",
        key: "requestFuneralDirector",
        status: "deprecated",
    },
    {
        label: "Dealing with family conflicts",
        key: "requestHelpDealingWithFamilyConflict",
        status: "deprecated",
    },
    {
        label: "Dealing with debt left behind",
        key: "requestHelpDealingWithDebt",
        status: "deprecated",
    },
    {
        label: "Accessing grief support",
        key: "requestLocalGriefSupport",
        status: "deprecated",
    },
    {
        label: "Seeking financial guidance",
        key: "requestFinancialGuidance",
        status: "deprecated",
    },
    {
        label: "Probate House Insurance",
        key: "requestProbateHouseInsurance",
        status: "deprecated",
    },
    {
        label: "Executor Insurance",
        key: "requestExecutorInsurance",
        status: "deprecated",
    },
] as const;

type ServiceDefinition = (typeof allAdditionalServices)[number];
type ActiveKeys = Extract<ServiceDefinition, { status: "active" }>["key"];
type DeprecatedKeys = Extract<ServiceDefinition, { status: "deprecated" }>["key"];

export type ActiveAdditionalHelpDetails = {
    [K in ActiveKeys]?: YesNo;
};

export type DeprecatedAdditionalHelpDetails = {
    [K in DeprecatedKeys]?: YesNo;
};

export type AdditionalHelpDetails = ActiveAdditionalHelpDetails & DeprecatedAdditionalHelpDetails;

export const additionalServicesMap = new Map<
    keyof AdditionalHelpDetails, string
>(
    allAdditionalServices.map(service => [service.key, service.label])
);


export const additionalServicesToUi = allAdditionalServices
    .filter((s) => s.status === "active")
    .map((s) => [s.label, s.key] as const);

export const deprecatedAdditionalServicesToUi = allAdditionalServices
    .filter((s) => s.status === "deprecated")
    .map((s) => [s.label, s.key] as const);

export const activeAdditionalServices = allAdditionalServices
    .filter((s) => s.status === "active")
    .map((s) => s.key);

export const deprecatedAdditionalServices = allAdditionalServices
    .filter((s) => s.status === "deprecated")
    .map((s) => s.key);

