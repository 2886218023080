import * as React from "react";

import { AccountDetails } from "@src/types";
import { cleanedErrors } from "@utils/Functions";
import { BooleanInput } from "@atoms/BooleanInput";
import { FormField } from "@molecules/FormField";
import { InfoBoxTrigger } from "@src/components/molecules";
import { useOnValueChange, useUpdater } from "@utils/callbacks";
import { PropertyStatus } from "../PropertyFields";
import { NewAccountRecord } from "@api/online-api";
import {
  accountFieldsFromRecord as meterAccountFieldsFromRecord,
  Errors as MeterInfoErrors,
  errorsOf as meterInfoErrorsOf,
  MeterInfoField,
  Record as MeterInfoRecord,
  recordFromAccount as meterInfoRecordFromAccount,
} from "./WaterAccountMeterInfoFields";
import { isNWG } from "@src/helpers/service-provider-helpers";

export type Record = {
  responsibleAcceptsArrears?: boolean;
  meterInfo: MeterInfoRecord;
};

export type Errors =
  | undefined
  | {
    meterInfo?: MeterInfoErrors;
  };

export const errorsOf: (r: Record) => Errors = (record) => {
  return cleanedErrors({
    meterInfo: meterInfoErrorsOf(record?.meterInfo),
  });
};

export const recordFromAccount = (account?: AccountDetails): Record => {
  return {
    responsibleAcceptsArrears: !account?.responsibleRejectsArrears,
    meterInfo: meterInfoRecordFromAccount(account),
  };
};

export const accountFieldsFromRecord = (record?: Record): Partial<NewAccountRecord> => {
  if (!record) {
    return {};
  }

  return {
    responsibleRejectsArrears: !record.responsibleAcceptsArrears,
    ...meterAccountFieldsFromRecord(record.meterInfo),
  };
};

export type WaterAccountFieldsProps = {
  record: Record;
  errors?: Errors;
  update: (f: (record: Record) => Record) => void;
  propertyStatus?: PropertyStatus;
  serviceProviderId?: string;
};

export const WaterAccountFields: React.FC<WaterAccountFieldsProps> = ({
  record,
  errors,
  update,
  propertyStatus,
  serviceProviderId,
}) => {
  const updateMeterInfo = useUpdater(update, "meterInfo");

  return (
    <>
      {propertyStatus === PropertyStatus.ExistingOccupier && (
        <ResponsibleAcceptsArrears
          record={record}
          update={update}
          serviceProviderId={serviceProviderId}
        />
      )}

      <MeterInfoField
        record={record.meterInfo}
        errors={errors?.meterInfo}
        update={updateMeterInfo}
      />
    </>
  );
};

const ResponsibleAcceptsArrears: React.FC<{
  record: Record;
  update: any;
  serviceProviderId?: string;
}> = ({ record, update, serviceProviderId }) => {
  const onValueChange = useOnValueChange(update, "responsibleAcceptsArrears");

  return (
    <FormField>
      <BooleanInput
        label={
          <span>
            I confirm that the existing occupant is{" "}
            <b>willing to accept responsibility for any arrears</b> (i.e. amounts owed) that may be
            on the accounts.
            {isNWG(serviceProviderId) && <InfoBoxTrigger content={renderInfoBox()} />}
          </span>
        }
        value={record.responsibleAcceptsArrears}
        onValueChange={onValueChange}
      />
    </FormField>
  );
};

const renderInfoBox = () => {
  return (
    <div>
      <p>
        <b>Why is this necessary?</b>
      </p>

      <p>
        Please note that if arrears are not accepted by the existing occupant, the existing account
        will be closed and a new account opened in the name of the existing occupant.
      </p>

      <p style={{ marginBottom: 0 }}>
        Any amounts owed or refunds due will be recovered from or paid to the estate of the person
        who died.
      </p>
    </div>
  );
};
