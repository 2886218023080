import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack } from "@mui/material";
import * as React from "react";
import { Account } from "../../types/Account";
import { Attachment } from "../../types/Attachment";
import { Case } from "../../types/Case";
import { deprecatedAdditionalServicesToUi, additionalServicesToUi, AdditionalHelpDetails, YesNo } from "@src/types/index";
import { Button } from "../../components/atoms/Button";
import { YesNoCheckboxField } from "../../components/atoms/YesNoField";
import { Accordion } from "../../components/molecules/Accordion";
import { updateAdditionalServices } from "@api/caseApi";
import { DashboardInfoContext } from "@src/store/DashboardProvider";
import { isDepreacatedService, servicesFromCase, servicesFromAccount } from "@src/helpers/additional-services-helpers";

export type AdditionalServicesProps = {
  caseId: string;
  signature: string | null;
  readonly caseRecord: Case & { accounts: Array<Account>; attachments?: Attachment[] };
};

export const AdditionalServicesAccordion: React.FC<AdditionalServicesProps> = (props) => {
  const [show, setShow] = React.useState(false);

  const showDialog = React.useCallback(() => setShow(true), [setShow]);
  const hideDialog = React.useCallback(() => setShow(false), [setShow]);

  return (
    <>
      <Accordion expanded={true} title="Additional services"> <AdditionalServices {...props} showDialog={showDialog} />
      </Accordion> <Dialog open={show} onClose={hideDialog}> <DialogTitle>Selected partners updated</DialogTitle>
        <DialogContent>
          <p>Our team will be in touch with you shortly to discuss your additional services.</p>
        </DialogContent>

        <DialogActions>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "40px",
              alignItems: "baseline"
            }}
          >
            <div>
              <Button variant="primary" size="small" onClick={hideDialog}>
                <span style={{ textTransform: "uppercase" }}>OK</span>
              </Button>
            </div>
          </div>
        </DialogActions> </Dialog>
    </>
  );
};
const AdditionalServices: React.FC<AdditionalServicesProps & { showDialog: () => void }> = ({
  caseId,
  signature,
  caseRecord,
  showDialog,
}) => {
  const [record, setRecord] = React.useState<AdditionalHelpDetails>(servicesFromCase(caseRecord));
  const { dispatch } = React.useContext(DashboardInfoContext);
  const additionalServicesAccount: ReadonlyArray<keyof AdditionalHelpDetails> = servicesFromAccount(caseRecord.accounts);

  const onSubmit = React.useCallback(() => {
    updateAdditionalServices({
      caseId,
      signature,
      record,
    }).then(
      () => {
        localStorage.setItem("additional-services-requested", "true");
        showDialog();
        dispatch({ type: "REFRESH" });
      },
      (error: any) => {
        console.error(error);
        showDialog();
      }
    );
  }, [caseId, signature, record, dispatch, showDialog]);


  return (
    <Stack rowGap={1}>
      <div>Select all that apply, and we’ll connect you to a trusted Settld Partner</div>

      <Grid container style={{ paddingLeft: "9px" }}>
        {additionalServicesToUi.map(([ui, internalField]) => (
          <DashboardAddtionalServices
            key={internalField}
            internalField={internalField}
            label={ui}
            record={record}
            setRecord={setRecord}

            exclude={additionalServicesAccount}
          />
        ))}
        {deprecatedAdditionalServicesToUi.map(([ui, internalField]) => (
          <DashboardAddtionalServices
            key={internalField}
            label={ui}
            internalField={internalField}
            record={record}
            setRecord={setRecord}
            exclude={additionalServicesAccount}

          />
        ))}
      </Grid>

      <Box>
        <Button variant="primary" size="small" onClick={onSubmit}>
          Submit
        </Button>
      </Box>
    </Stack>
  );
};

interface DashboardAddtionalServicesProps {
  label: string;
  internalField: keyof AdditionalHelpDetails;
  record: AdditionalHelpDetails;
  setRecord: React.Dispatch<React.SetStateAction<AdditionalHelpDetails>>;
  // if account has been creaated already for that service, stil show the services in accordion
  exclude: ReadonlyArray<keyof AdditionalHelpDetails>;
}

const DashboardAddtionalServices: React.FC<DashboardAddtionalServicesProps> = ({
  internalField,
  label,
  record,
  setRecord, exclude
}) => {
  const onFieldChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value?: string } }) => {
      setRecord((prev) => ({ ...prev, [name]: value }));
    },
    [setRecord]
  );

  // Conditionally hide if it's in the deprecated list and not "YES"
  if (!exclude.includes(internalField) && isDepreacatedService(internalField) && record[internalField] !== YesNo.Yes) return null;


  const onFieldValueChange = (name: string) => (value: YesNo) =>
    onFieldChange({ target: { name, value } });

  return (
    <Grid item xs={12} md={4}>
      <YesNoCheckboxField
        noPadding
        smallFont
        label={label}
        value={record[internalField]}
        onValueChange={onFieldValueChange(internalField)}
      />
    </Grid>
  );
};

export default AdditionalServices;