import { NonExpressFormStep, } from "../Sections";
import { AccountDetails } from "./Account";
import { DocumentsDetails } from "./Document";
import { Person, NotifierDetails, DeceasedDetails, Executor, Nok } from "./Person";

export interface NonExpressFormData {
  readonly notifier?: NotifierDetails;
  readonly accounts?: ReadonlyArray<AccountDetails>;
  readonly deceased?: DeceasedDetails;
  readonly nok?: Nok;
  readonly executor?: Executor;
  readonly documents?: DocumentsDetails;
  readonly submittedSections: NonExpressFormStep;
  readonly kycPending: boolean;
  readonly submitted: boolean;
}

export enum YesNo {
  Yes = "YES",
  No = "NO",
}


// Helper functions and objects.
export const fullDetailsRequiredFields: ReadonlyArray<keyof Person> = [
  "firstName",
  "lastName",
  "address",
  "postcode",
  "contactNumber",
  "email",
  "title",
  "city",
];

export * from "./Person";
export * from "./ServiceProvider";