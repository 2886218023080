import { Connection } from "./Relay";
import { Identifiable } from "./Identifiable";
import { assertUnreachable } from "../utils/Functions";

export interface ServiceProvider {
  readonly accountManagement?: AccountManagement;
  readonly approved?: boolean;
  readonly cases?: Connection<Identifiable>;
  readonly companyName: string;
  readonly customForm?: string;
  readonly deathCertificateNeeded?: boolean;
  readonly expressFormLandingCopy?: string;
  readonly faceSimilarityNeeded?: boolean;
  readonly grantOfRepresentationNeeded?: boolean;
  readonly id?: string;
  readonly idDocumentNeeded?: boolean;
  readonly inPartnership?: boolean;
  readonly intestacyFlow?: boolean;
  readonly isConnected?: boolean;
  readonly logoUrl?: string;
  readonly proofOfAddressNeeded?: boolean;
  readonly serviceProviderType: ServiceProviderType;
  readonly slaDays?: string;
  readonly slug?: string;
  readonly willNeeded?: boolean;
}

export enum AccountManagement {
  Managed = "managed",
  NotifyOnly = "notify_only",
  NotManaged = "not_managed",
  Unknown = "unknown"
}

export enum ServiceProviderType {
  Banking = "banking",
  Betting = "betting",
  Broadband = "broadband",
  BuildingSociety = "building_society",
  Charities = "charities",
  CreditCards = "credit_cards",
  Ecommerce = "ecommerce",
  Email = "email",
  Energy = "energy",
  Insurance = "insurance",
  Investments = "investments",
  Loans = "loans",
  LoyaltyCards = "loyalty_cards",
  Magazines = "magazines",
  Mobile = "mobile",
  Mortgage = "mortgage",
  Other = "other",
  Pension = "pension",
  SaaS = "saas",
  Satellite = "satellite",
  SocialMedia = "social_media",
  Streaming = "streaming",
  Subscriptions = "subscriptions",
  Telecoms = "telecoms",
  TV = "tv",
  Water = "water",
  affiliatePartner = "affiliate_partner",
}

export const readableServiceProviderType = (
  t?: ServiceProviderType,
  fallback?: string
): string => {
  switch (t) {
    case ServiceProviderType.Banking:
      return "Banking";
    case ServiceProviderType.Betting:
      return "Betting";
    case ServiceProviderType.Broadband:
      return "Broadband";
    case ServiceProviderType.BuildingSociety:
      return "Building Society";
    case ServiceProviderType.Charities:
      return "Charities";
    case ServiceProviderType.CreditCards:
      return "Credit cards";
    case ServiceProviderType.Ecommerce:
      return "E-commerce";
    case ServiceProviderType.Email:
      return "Email";
    case ServiceProviderType.Energy:
      return "Energy";
    case ServiceProviderType.Insurance:
      return "Insurance";
    case ServiceProviderType.Investments:
      return "Investments";
    case ServiceProviderType.Loans:
      return "Loans";
    case ServiceProviderType.LoyaltyCards:
      return "Loyalty cards";
    case ServiceProviderType.Magazines:
      return "Magazines";
    case ServiceProviderType.Mobile:
      return "Mobile";
    case ServiceProviderType.Mortgage:
      return "Mortgage";
    case ServiceProviderType.Pension:
      return "Pension";
    case ServiceProviderType.SaaS:
      return "SaaS";
    case ServiceProviderType.Satellite:
      return "Satellite";
    case ServiceProviderType.SocialMedia:
      return "Social media";
    case ServiceProviderType.Streaming:
      return "Streaming";
    case ServiceProviderType.Subscriptions:
      return "Subscriptions";
    case ServiceProviderType.Telecoms:
      return "Telecommunications";
    case ServiceProviderType.TV:
      return "Television";
    case ServiceProviderType.Water:
      return "Water";
    case ServiceProviderType.Other:
      return "Other";
    case ServiceProviderType.affiliatePartner:
      return "Partner";
    case undefined:
    case null:
      return fallback || "Unknown";
  }
  assertUnreachable(t);
};
