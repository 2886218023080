export function isOctopusEnergy(id: string | undefined | null): boolean {
    if (!id) return false;
    return (
        id === "e66ef322-387b-4e94-84bd-533fbd62c152" || //oe production 
        id === "e7fcff6d-c950-476f-85fb-4c79d7cef117"  //oe staging
    );
}

export const isNWG = (id: string | undefined | null): boolean => {
    if (!id) return false;

    return (
        id === "1008694e-f7db-4eec-8f07-b9fae3fb50f4" || // nwg prod
        id === "d2969def-1081-4a44-9c47-a5fed894edd3" || // esw prod
        id === "1bb7b20d-f22a-4a96-9aca-94785741a673" || // nwg staging
        id === "11c71ab0-69f5-44a1-a937-1a0ddf9ba1ea" // esw staging
    );
};