import * as React from "react";
import { isNWG } from "@src/helpers/service-provider-helpers";
import { cleanedErrors } from "@utils/Functions";
import { DateInput } from "@atoms/DateInput";
import { YesNoBooleanRadioField } from "@atoms/YesNoField";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { InfoBoxTrigger } from "@src/components/molecules";
import { useOnValueChange } from "@utils/callbacks";
import { AccountRecordPropertySpec } from "@api/online-api";

export type Record = {
  readonly lastOccupiedBoolean?: boolean;
  readonly lastOccupiedDate?: string;
};

export type Errors =
  | undefined
  | {
    lastOccupiedBoolean?: string;
    lastOccupiedDate?: string;
  };

export const propertyFieldsFromRecord: (record?: Record) => Partial<AccountRecordPropertySpec> = (
  record
) => {
  if (!record) {
    return {};
  }

  return {
    lastOccupiedDate: record.lastOccupiedBoolean ? undefined : record.lastOccupiedDate,
  };
};

export const errorsOf: (r?: Record) => Errors = (r) => {
  return cleanedErrors({
    lastOccupiedBoolean: typeof r?.lastOccupiedBoolean === "boolean" ? undefined : "required",
    lastOccupiedDate: r?.lastOccupiedDate || r?.lastOccupiedBoolean ? undefined : "required",
  });
};

export const LastOccupiedDateField: React.FC<{
  record: Record;
  update: (newRecord: Record) => void;
  errors: Errors;
  serviceProviderId?: string;
}> = ({ record, update, errors, serviceProviderId }) => {
  const onLastOccupiedBooleanValueChange = useOnValueChange(update, "lastOccupiedBoolean");
  const onLastOccupiedDateChange = useOnValueChange(update, "lastOccupiedDate");

  return (
    <FormStack substack spacing={0}>
      <FormField
        label={
          <span>
            When did the person who died last occupy the property?{" "}
            {isNWG(serviceProviderId) && <InfoBoxTrigger content={renderInfoBox()} />}
          </span>
        }
      >
        <YesNoBooleanRadioField
          value={record.lastOccupiedBoolean}
          onValueChange={onLastOccupiedBooleanValueChange}
          error={errors?.lastOccupiedBoolean}
          yesLabel="On the date of death"
          noLabel="Other date (eg. the person who died was in hospital care before passing away)"
        />
      </FormField>

      {record.lastOccupiedBoolean === false && (
        <FormStack indented substack>
          <FormField label={<i>Please enter the date the property was last occupied:</i>}>
            <DateInput
              value={record.lastOccupiedDate || ""}
              onValueChange={onLastOccupiedDateChange}
              error={errors?.lastOccupiedDate}
              pastOnly
            />
          </FormField>
        </FormStack>
      )}
    </FormStack>
  );
};

const renderInfoBox = () => {
  return (
    <div>
      <p>
        <b>Why do I need to provide this information?</b>
      </p>

      <p style={{ marginBottom: 0 }}>
        This helps us determine the date on which the account should be closed, ie. if the person
        who died last occupied the property on the date of death, the account will be closed from
        that date.
      </p>
    </div>
  );
};
