import { CheckCircle, CloseOutlined } from "@mui/icons-material";
import { Box, Container, Stack } from "@mui/material";
import * as React from "react";
import { createDocument, s3Upload } from "@api/caseApi";
import { Document } from "../../types/Document";
import { filterMap } from "../../utils/Functions";
import { Link } from "../atoms/Link";
import { BasicDropzone } from "../molecules/BasicDropzone";

export type UploadsRecord = {
  readonly originalIds: ReadonlyArray<string>;
  readonly currentIds: ReadonlyArray<string>;
  readonly previousDocuments: ReadonlyArray<Document>;
  readonly files: ReadonlyArray<File>;
};
export const initialRecord = (ids?: ReadonlyArray<string>) => {
  ids = ids ?? [];
  return { originalIds: ids, currentIds: ids, previousDocuments: [], files: [] };
};

export type UploadFieldProps = {
  uploadedFileInfo: (id: string) => Promise<Document>;
  busy: boolean;
  record: UploadsRecord;
  update: (a: (a: UploadsRecord) => UploadsRecord, initial?: boolean) => void;
  error?: string;
  setError: (message: string) => void;
  tag?: string;
};

export const UploadField: React.FC<UploadFieldProps> = ({
  uploadedFileInfo,
  busy,
  record: { originalIds, previousDocuments, files },
  update,
  error,
  setError,
  tag,
}) => {
  // TODO:
  // when wrong file is dropped to drag* drop area, it removes existing files that were added
  // When all files added are of acceptable type drop the red warning from ui.
  React.useEffect(() => {
    Promise.all(originalIds?.map(uploadedFileInfo) ?? []).then(
      (documents) => {
        if (tag) documents = documents.filter(({ tags }) => tags?.includes(tag));
        update(
          (s) => ({
            ...s,
            currentIds: documents.map(({ id }) => id),
            previousDocuments: documents,
          }),
          true
        );
      },
      (error: Error) => {
        console.warn(error);
        update((s) => ({ ...s, currentIds: [], previousDocuments: [] }), true);
      }
    );
  }, [originalIds]);

  const remove = React.useCallback((id) => {
    update((s) => ({
      ...s,
      currentIds: s.currentIds.filter((existing) => existing !== id),
      previousDocuments: s.previousDocuments.filter((existing) => existing.id !== id),
    }));
  }, []);

  const handleDrop = React.useCallback(
    (fs: File[]) => {
      update((s) => ({ ...s, files: fs }));
    },
    [update]
  );

  const removeFileAtIndexProc = React.useCallback(
    (index) => {
      return (ev: React.MouseEvent) => {
        ev.preventDefault();
        handleDrop([...files.slice(0, index), ...files.slice(index + 1)]);
      };
    },
    [files, handleDrop]
  );


  return (
    <Stack rowGap={2}>
      <BasicDropzone
        files={files ? files : undefined}
        errors={!error ? undefined : ([error] as readonly string[])}
        onDrop={handleDrop}
        isUploading={busy}
        multiple={true}
        setError={setError}
      />

      <Container>
        <Stack>
          {previousDocuments && <PreviousDocuments documents={previousDocuments} remove={remove} />}
          {files && (
            <>
              {files.map((file, index) => (
                <Stack key={index} direction="row" alignItems="start" columnGap={1}>
                  <span style={{ verticalAlign: "sub" }}>
                    <CheckCircle fontSize="small" color="success" />
                  </span>
                  <Box>{file.name}</Box>
                  <a
                    style={{ color: "#b00000", cursor: "pointer", textDecoration: "none" }}
                    onClick={removeFileAtIndexProc(index)}
                  >
                    <span style={{ verticalAlign: "top" }}>
                      remove
                    </span>
                    <span style={{ verticalAlign: "sub" }}>
                      <CloseOutlined fontSize="small" />
                    </span>
                  </a>
                </Stack>
              ))}
            </>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};


const PreviousDocuments: React.FC<{
  readonly documents: ReadonlyArray<Document>;
  readonly remove: (id: string) => void;
}> = (props) => {
  const { documents: files, remove } = props;

  return (
    <>
      {files.map((file, index) => (
        <Stack key={index} direction="row" alignItems="start" columnGap={1}>
          <span style={{ verticalAlign: "sub" }}>
            <CheckCircle fontSize="small" color="success" />
          </span>
          <Link
            download={file.filename}
            target="_blank"
            rel="noopener noreferrer"
            to={file.downloadUrl}
          >
            {file.filename}
          </Link>
          <a style={{ color: "#b00000", cursor: "pointer" }} onClick={() => remove(file.id)}>
            <span style={{ verticalAlign: "top" }}>
              remove
            </span>
            <span style={{ verticalAlign: "sub" }}>
              <CloseOutlined fontSize="small" />
            </span>
          </a>
        </Stack>
      ))}
    </>
  );
};

export const documentIdsFromUploadsRecord = (
  caseId: string,
  signature: string | null,
  record?: UploadsRecord,
  tag?: string
) => {
  if (!record) return Promise.resolve([]);
  return Promise.all(record.files)
    .then((files) => {
      const promises = files.map((file: File) =>
        createDocument({
          caseId,
          signature,
          filename: file.name,
          tags: tag ? [tag] : undefined,
        }).then((result) => ({
          file,
          result,
        }))
      );
      return Promise.all(promises);
    })
    .then((filesAndResults) => {
      const promises = filesAndResults.map(({ file, result }: any) => {
        if (!result.data) {
          return Promise.reject("Missing payload from document creation");
        }
        const { document, uploadUrl } = result.data;
        return s3Upload(uploadUrl, file).then((result) => ({
          documentId: document.id,
          result,
        }));
      });
      return Promise.all(promises);
    })
    .then((documentIdsAndResults) => {
      return filterMap(documentIdsAndResults, ({ documentId, result }: any) => {
        return result.status === 200 ? documentId : null;
      });
    })
    .then((documentIds) => {
      return record.currentIds.concat(documentIds);
    });
};
