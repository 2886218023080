import * as React from "react";
import { RadioGroupInput } from "@src/components/atoms";
import { FormField } from "@molecules/FormField";
import { InfoBoxTrigger } from "@src/components/molecules";
import { newPropertyStatusFields, PropertyStatus } from "@src/types/property";
import { isNWG, isOctopusEnergy } from "@src/helpers/service-provider-helpers";

export type PropertyStatusFieldsProps = {
  value?: PropertyStatus;
  error?: string;
  onValueChange: any;
  serviceProviderId?: string;
};

export const PropertyStatusField: React.FC<PropertyStatusFieldsProps> = ({
  value,
  error,
  onValueChange,
  serviceProviderId,
}) => {
  return (
    <FormField
      label={
        <span>
          What is the current status of the property?{" "}
          {serviceProviderId && (isOctopusEnergy(serviceProviderId) || isNWG(serviceProviderId)) && <InfoBoxTrigger content={<InfoBox serviceProviderId={serviceProviderId} />} />}
        </span>
      }
    >
      <RadioGroupInput
        onValueChange={onValueChange}
        value={value}
        fields={newPropertyStatusFields}
        error={error}
      />
    </FormField>
  );
};

interface IInfoBox {
  serviceProviderId: undefined | string;
}
const InfoBox: React.FC<IInfoBox> = ({ serviceProviderId }) => {
  if (isOctopusEnergy(serviceProviderId)) {
    return (
      <div>
        <p>
          If you're handling an estate and need to sell a property, just let us know if it's vacant—we'll make sure the utilities stay on until you're ready to provide final readings.
        </p>
        <p>
          If someone is living in the property, simply let us know by selecting one of the other two options, we'll then request that the account details are updated for you.
        </p>
      </div>
    );
  }
  if (isNWG(serviceProviderId)) {

    return (
      <div>
        <p>
          <b>Why do I need to provide this information?</b>
        </p>

        <p>
          Please note we need to understand the property status to determine what happens to the
          account.
        </p>

        <p>
          If the property is vacant or new occupants have moved in, we will assist you in closing the
          account.
        </p>

        <p style={{ marginBottom: 0 }}>
          If the property continues to be occupied by a surviving occupant, we will assist you in
          transferring the account to that surviving occupant.
        </p>
      </div>
    );
  }
  return null;
};
