import * as React from "react";
import { DropEvent, DropzoneOptions, FileError, FileRejection, useDropzone } from "react-dropzone";
import { CircularProgress } from "@mui/material";
import { compress, fileSizeErrorByMime, isAllowedMimeType, isValidSize } from "@src/helpers/documents-helpers";
import { READABLE_ACCEPTABLE_MIME_TYPES, } from "@src/utils/Constants";
interface BasicDropzoneProps {
  readonly files?: ReadonlyArray<File> | File;
  readonly multiple?: boolean;
  readonly isUploading?: boolean;
  readonly errors?: ReadonlyArray<string>;
  readonly onDrop: (f: File[]) => void;
  readonly setError: any
}
export const BasicDropzone: React.FC<BasicDropzoneProps> = ({
  files,
  multiple,
  isUploading,
  errors,
  onDrop, setError
}) => {
  // expression resolves undefined/not array to array or []
  const acceptedFiles = React.useMemo(
    () => (!files ? [] : !Array.isArray(files) ? [files] : files),
    [files]
  );

  const onFileDrop = React.useCallback(
    (newFiles) => {
      if (!multiple) {
        onDrop(newFiles);
        return;
      }
      const existingFileNames = acceptedFiles.map((f) => f.name);
      newFiles = Array.isArray(newFiles) ? newFiles : [newFiles];
      newFiles = newFiles.filter((file: any) => file && !existingFileNames.includes(file.name));
      onDrop(acceptedFiles.concat(newFiles));
    },
    [acceptedFiles, multiple, onDrop]
  );

  const onDropRejected = React.useCallback(
    (t: FileRejection[]) => {
      setError(t[0].errors.map((e) => e.message)[0]);
    },
    [setError]
  );

  const onDropAccept = React.useCallback(() => {
    setError(undefined)
  }, [])

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop: onFileDrop,
    onDropRejected,
    multiple,
    getFilesFromEvent: interceptFileDropEvent,
    validator: customValidator,
    onDropAccepted: onDropAccept
  });

  const zoneClassName = [
    "BasicDropzone-zone",
    // HACK to always show darker chars
    isDragAccept || "accept",
    isDragReject || (errors && errors.length > 0) ? "reject" : "",
    isUploading ? "isUploading" : "",
  ].join(" ");

  return (
    <div className="BasicDropzone">
      <div className={zoneClassName} {...getRootProps()}>
        <input {...getInputProps()} disabled={isUploading} />
        <Message
          acceptedFiles={acceptedFiles}
          errors={errors}
          isUploading={!!isUploading}
          multiple={!!multiple}
        />
      </div>
    </div>
  );
};

const Message = ({
  acceptedFiles,
  errors,
  isUploading,
  multiple,
}: {
  acceptedFiles: unknown[];
  isUploading: boolean;
  errors?: ReadonlyArray<string>;
  multiple: boolean;
}) => {

  if (isUploading) {
    return (
      <>
        <CircularProgress color="primary" />
        <p>Uploading...</p>
      </>
    );
  }

  if (multiple || acceptedFiles.length === 0 || errors) {
    return (
      <>
        <p>Drag and drop a document, or click to upload.</p>
        <em>(*.pdf, *.jpeg, *.png)</em>
        {errors && (
          <>
            {errors.map((e, idx) => (
              <p key={idx}>{e.split("\n")}</p>
            ))}
          </>
        )}
      </>
    );
  }

  return (
    <>
      <p>Drop another document to replace or click here.</p>
      <em>(*.pdf, *.jpeg, *.png)</em>
      {errors && (
        <>
          {/* @ts-ignore */}
          {errors.map((e, idx) => (
            <p key={idx}>{e.split("\n")}</p>
          ))}
        </>
      )}
    </>
  );
};

const interceptFileDropEvent: DropzoneOptions["getFilesFromEvent"] =
  async (event: DropEvent): Promise<Array<File>> => {
    let fileList: File[] = [];

    if ("dataTransfer" in event && event.dataTransfer) {
      fileList = Array.from(event.dataTransfer.files);
    }
    else if ("target" in event && event.target && (event.target as HTMLInputElement).files) {
      fileList = Array.from((event.target as HTMLInputElement).files!);
    }

    return Promise.all(fileList.map(file => compress(file)));
  };

const customValidator: DropzoneOptions["validator"] = (file: File): readonly FileError[] | null => {
  const errors: FileError[] = [];

  if (!isAllowedMimeType(file.type)) {
    errors.push({
      message: `${file.name}: Accepted File Types: ${READABLE_ACCEPTABLE_MIME_TYPES}`, code: "file-invalid-type"
    })
  }

  if (!isValidSize(file.size)) {
    errors.push({
      message: `${file.name}: ${fileSizeErrorByMime(file.type)}`,
      code: "file-too-large"
    })
  }

  return errors.length === 0 ? null : errors;
};