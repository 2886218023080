import { CaseEvent } from "./Case";
import { PersonDetails, YesNo } from "./FormData";
import { ServiceProviderType, ServiceProvider, AccountManagement } from "./ServiceProvider";
import { Property } from "@src/types/property";
import { AdditionalHelpDetails, DocumentShell } from "@customTypes/index";

export enum EnergyType {
  Gas = "gas",
  Electricity = "electricity",
  DualFuel = "dual_fuel",
}

export enum MeterAccess {
  Yes = "yes",
  Later = "later",
  No = "no",
  NotMetered = "not_metered",
}
export enum AccountNextStep {
  Cancel = "CANCEL",
  MoveToPayAsYouGo = "MOVE_TO_PAY_AS_YOU_GO",
  MoveToAnotherService = "MOVE_TO_ANOTHER_SERVICE",
  RequestAccountBalance = "REQUEST_ACCOUNT_BALANCE",
  UpdateAccountHolder = "UPDATE_ACCOUNT_HOLDER",
  RequestInformation = "REQUEST_INFORMATION",
}

export enum RefundPaymentMethod {
  BankTransferToAssociatedAccount = "bank_transfer_to_associated_account",
  BankTransferToNewAccount = "bank_transfer_to_new_account",
  Cheque = "cheque",
}

export enum MeterType {
  SmartMeter = "smart_meter",
  Credit = "credit",
  PayAsYouGo = "pay_as_you_go",
  Unknown = "unknown",
}

export enum AccountType {
  CurrentAccount = "current_account",
  SavingsAccount = "savings_account",
  CashISA = "cash_isa",

  RetirementAccount = "retirement_account",
  Annuities = "annuities",
  WorkplacePension = "workplace_pension",

  Remortgage = "remortgage",
  FirstTimeBuyer = "first_time_buyer",
  BuyToLet = "buy_to_let",

  Vehicle = "vehicle",
  BreakdownCover = "breakdown_cover",
  Home = "home",
  Health = "health",
  Travel = "travel",
  Business = "business",
  Life = "life",
  Disability = "disability",
  Pet = "pet",

  PayAsYouGo = "pay_as_you_go",
  Contract = "contract",

  Personal = "personal",
  PersonalAndBusiness = "personal_and_business",

  PersonalAccidentInsurance = "personal_accident_insurance",
  PrivateMedicalInsurance = "private_medical_insurance",
  HospitalCashPlan = "hospital_cash_plan",
  CancerCover = "cancer_cover",

  Other = "other",
  Unknown = "unknown",
}
export enum AccountStates {
  Closed = "CLOSED",
  Open = "OPEN",
}

export enum AccountStatuses {
  Processing = "PROCESSING", //in progress
  Settled = "SETTLED", // completed
  Blocked = "BLOCKED", //blocked
  Cancelled = "CANCELLED", //cancelled
}

// TODO: [backend] accountNumber, nextSteps, lastMeterReading are coming back as null instead of undefined.
export interface Account {
  readonly id?: string;
  readonly insertedAt?: string;
  // readonly case: Identifiable;
  readonly initialLetterSentAt?: string;
  readonly serviceProvider?: ServiceProvider;
  readonly state?: AccountStates;
  readonly status?: AccountStatuses;
  readonly propertyRemainsOccupied?: boolean;
  readonly finalBalance?: number;
  readonly outcomeDetails?: string;
  readonly accountEvents?: ReadonlyArray<CaseEvent>;
  readonly accountNumber?: string;
  readonly estimateSettledBy?: string;

  readonly property?: Property;
  readonly responsible?: PersonDetails;
  readonly requiresNotifierAttention?: boolean;
  readonly unreadMsg?: boolean;
  readonly additionalServicesAccount?: boolean;
  readonly additionalServicesDetails?: {
    type: keyof AdditionalHelpDetails;
  };

  readonly companyId?: string;
  readonly serviceProviderType?: ServiceProviderType;
  readonly accountManagement?: AccountManagement;
  readonly companyName?: string;
  readonly sortCode?: string;
  readonly holderNumber?: string;
  readonly accountType?: AccountType;
  readonly accountTypeOther?: string;
  readonly nextSteps?: AccountNextStep;
  readonly documentIds?: ReadonlyArray<string>;
  readonly documents?: ReadonlyArray<DocumentShell>;
  readonly responsibleId?: string;
  readonly propertyId?: string;
  readonly source?: "express_form";

  // Utilities
  readonly gasMeterType?: MeterType;
  readonly electricityMeterType?: MeterType;
  readonly gasLastMeterReadingDate?: string;
  readonly electricityLastMeterReadingDate?: string;
  //
  readonly lastMeterReading?: string;
  readonly meterType?: MeterType;
  readonly lastMeterReadingDate?: string;
  readonly meterAccess?: MeterAccess;
  readonly meterNumber?: string;
  readonly responsibleRejectsArrears?: boolean;
  readonly energyType: EnergyType;

  // Banks & Insurances
  //readonly sortCode?: string;
  //readonly holderNumber?: string;
  //readonly accountType?: string;
  readonly needsTaxCertificate?: boolean;
  readonly vehicleMake?: string;
  readonly vehicleModel?: string;
  readonly vehicleRegistrationNumber?: string;
  readonly accountName?: string;
  readonly holderOrUser?: "holder" | "user" | "both";
  // Banks
  // readonly useToPayFuneralExpenses?: YesNo;

  // Insurances
  readonly jointClaim?: YesNo;
  readonly refundPaymentMethod?: RefundPaymentMethod;
  readonly refundBankAccountHolderName?: string;
  readonly refundBankAccountNumber?: string;
  readonly refundBankAccountSortCode?: string;
  readonly refundChequePayableTo?: "notifier" | "responsible";
  readonly paymentBankAccountHolderName?: string;
  readonly paymentBankAccountNumber?: string;
  readonly paymentBankAccountSortCode?: string;
  ///Social Media

  readonly emailAddress?: string;
  readonly phoneNumber?: string;
  readonly profileUrl?: string;

  // payment information
  readonly useCreditInNextBill?: boolean;
  readonly nextBillDueMonth?: string;
  readonly paymentPlanInPlace?: boolean;
  readonly paymentAmount?: boolean;
  readonly paymentFrequency?: string;
  readonly nextPaymentDueOn?: string;
  readonly paymentMethod?: string;
}

export type AccountDetails = Account;